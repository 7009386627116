<template>
    <!--- Navigation --->
    <nav class="section__navigation navigation pfull__12">
    </nav>
</template>

<script>
    import { mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: "VideoList_navigation",
        props: [],
        components: {},
        data() {
            return {
                FilterSearchFocusin: false,
            }
        },
        computed: {
            ...mapGetters([
                "showFilterDropdownMenu",
            ]),
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
                "setSectionWidget"
            ]),
            ...mapMutations([
                "switchShowFilterDropdownMenu",
                "setShowFilterDropdownMenu",
            ]),
            displaySectionInfo(component){
                this.setSectionInfo({
                    component: component,
                    props: {
                        unitId: null,
                        time: Date.now()
                    }
                })
            },
            displaySectionWidget(component){
                this.setSectionWidget({
                    component: component,
                    props: {
                        unitId: null,
                        time: Date.now()
                    }
                })
            },
        },
        updated() {
            //console.log(`VideoList_navigation updated`)
        },
        mounted() {
        },
    }
</script>

<style scoped>

</style>